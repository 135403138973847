<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('pump_install.irrigation_farmer') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.name')"
              label-for="name"
            >
              <b-form-input
                plain
                id="name"
                v-model="search.name"
                >
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.nid')"
              label-for="nid"
            >
              <b-form-input
                plain
                id="nid"
                v-model="search.nid"
                >
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.division')"
              label-for="far_division_id"
            >
              <b-form-select
                plain
                id="far_division_id"
                v-model="search.far_division_id"
                :options="divisionList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.district')"
              label-for="far_district_id"
            >
              <b-form-select
                plain
                id="far_district_id"
                v-model="search.far_district_id"
                :options="districtList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('pump_install.upazila')"
              label-for="far_upazilla_id"
            >
              <b-form-select
                plain
                id="far_upazilla_id"
                v-model="search.far_upazilla_id"
                :options="upazilaList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="6" sm="12" xs="12">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pump_install.irrigation_farmer') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new mr-2" @click="pdfExport">
              <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
            </b-button>
            <export-excel
              class="btn btn_add_new"
              :data="dataCustomize"
              :fields= "json_fields"
              :title="$t('pump_install.irrigation_farmer')"
              :worksheet="$t('pump_install.irrigation_farmer')"
              name="irrigation-farmer.xls">
              <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
            </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="farmerListData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(far_mobile_no)="data">
                      {{ $n('0') + $n(data.item.far_mobile_no, { useGrouping: false }) }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { irrigationFarmerList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import excel from 'vue-excel-export'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ExportPdf from './list_export_pdf'
Vue.use(excel)

const excelColumn = {
  'Serial No': 'sl_no',
  Name: 'name',
  Email: 'email',
  NID: 'nid',
  'Mobile Number': 'mobile_no',
  'Father Name': 'father_name',
  'Mother Name': 'mother_name'
}
const excelColumnBn = {
  'ক্রমিক নং': 'sl_no',
  নাম: 'name',
  ইমেইল: 'email',
  এনআইডি: 'nid',
  'মোবাইল নম্বর': 'mobile_no',
  'পিতার নাম': 'father_name',
  'মাতার নাম': 'mother_name'
}

export default {
  mixins: [ModalBaseMasterList],
  data () {
    return {
      irriSchemeServiceBaseUrl: irriSchemeServiceBaseUrl,
      search: {
        far_division_id: 0,
        far_district_id: 0,
        far_upazilla_id: 0,
        name: '',
        nid: ''
      },
      errorMsz: '',
      id: 0,
      rows: [],
      farmerListData: [],
      districtList: [],
      upazilaList: []
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    json_fields: function () {
        return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    currentLocale () {
      return this.$i18n.locale
    },
    dataCustomize () {
      const listData = this.farmerListData
      let slNo = 1
      const listContractor = listData.map(item => {
        return Object.assign({}, item, {
          sl_no: this.$n(slNo++, { useGrouping: false }),
          name: this.$i18n.locale === 'en' ? item.name : item.name_bn,
          email: item.email,
          nid: this.$n(item.nid, { useGrouping: false }),
          mobile_no: this.$n('0') + this.$n(item.mobile_no, { useGrouping: false }),
          father_name: this.$i18n.locale === 'en' ? item.father_name : item.father_name_bn,
          mother_name: this.$i18n.locale === 'en' ? item.mother_name : item.mother_name_bn
        })
      })
      return listContractor
    },
    formTitle () {
      return (this.id === 0) ? this.$t('irrigation_config.scheme_type_entry') : this.$t('irrigation_config.scheme') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            { label: this.$t('pump_install.name'), class: 'text-center' },
            { label: this.$t('pump_install.email'), class: 'text-center' },
            { label: this.$t('pump_install.nid'), class: 'text-center' },
            { label: this.$t('pump_install.mobile_number'), class: 'text-center' },
            { label: this.$t('pump_install.father_name'), class: 'text-center' },
            { label: this.$t('pump_install.mother_name'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'index' },
            { key: 'name_bn' },
            { key: 'email' },
            { key: 'nid' },
            { key: 'mobile_no' },
            { key: 'father_name_bn' },
            { key: 'mother_name_bn' }
          ]
        } else {
          keys = [
            { key: 'index' },
            { key: 'name' },
            { key: 'email' },
            { key: 'nid' },
            { key: 'mobile_no' },
            { key: 'father_name' },
            { key: 'mother_name' }
          ]
        }

        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'search.far_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.far_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.search.far_division_id) {
              this.districtList = this.getDistrictList()
            }
            if (this.search.far_district_id) {
              this.upazilaList = this.getUpazilaList()
            }
        }
    }
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        far_division_id: this.authUser.office_detail.division_id,
        far_district_id: this.authUser.office_detail.district_id,
        far_upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
    this.loadData()
  },
  methods: {
    searchData () {
      if (this.search.org_id !== 0) {
        this.errorMsz = ''
        if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
          this.search = Object.assign({}, this.search, {
            org_id: this.authUser.org_id
          })
        } else if (this.authUser.role_id === 0) {
          this.search = Object.assign({}, this.search, {
            org_id: this.authUser.office_detail.org_id,
            far_division_id: this.authUser.office_detail.division_id,
            far_district_id: this.authUser.office_detail.district_id,
            far_upazilla_id: this.authUser.office_detail.upazilla_id
          })
        }
        this.loadData()
      } else {
        this.errorMsz = 'Requird'
      }
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      await RestApi.getData(irriSchemeServiceBaseUrl, irrigationFarmerList, this.search)
      .then(response => {
        if (response.success) {
          this.farmerListData = response.data.data
        } else {
          this.farmerListData = []
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    pdfExport () {
      const reportTitle = this.$t('pump_install.irrigation_farmer') + this.$t('globalTrans.report')
      ExportPdf.exportPdfDetails(irriSchemeServiceBaseUrl, '/report-heading/detail', 3, reportTitle, this, this.farmerListData)
    }
  }
}
</script>
<style scoped>
p[data-v-745cfd20]{
  margin: 0px !important;
  padding: 0px !important;
}
</style>
