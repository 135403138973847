import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'

function getBase64ImageFromURL (url) {
  return new Promise((resolve, reject) => {
    var img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')

    img.onload = () => {
      var canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      var ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      var dataURL = canvas.toDataURL('image/png')

      resolve(dataURL)
    }

    img.onerror = error => {
      reject(error)
    }

    img.src = url
  })
}

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, thisObject, data) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      {
        columns: reportHeadData.reportHeadColumn
      },
      { text: reportHeadData.address, style: 'header3', alignment: 'center' }
      ]

      if (reportHeadData.projectName) {
        pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
      }

      pdfContent.push({ text: reportTitle, style: 'header3', alignment: 'center' })
        // Progress Types
        const progressTypesHeadList = [
          { text: thisObject.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
          { text: thisObject.$t('pump_install.name'), style: 'th', alignment: 'center' },
          { text: thisObject.$t('pump_install.email'), style: 'th', alignment: 'center' },
          { text: thisObject.$t('pump_install.nid'), style: 'th', alignment: 'center' },
          { text: thisObject.$t('pump_install.mobile_number'), style: 'th', alignment: 'center' },
          { text: thisObject.$t('pump_install.father_name'), style: 'th', alignment: 'center' },
          { text: thisObject.$t('pump_install.mother_name'), style: 'th', alignment: 'center' }
        ]

        const allRowsProgressTypes = [progressTypesHeadList]
        const mappingData = data.map((item, index) => {
          const imageData = {}
          imageData.image = item.attachment !== null || item.attachment !== '' ? getBase64ImageFromURL(baseUrl + 'download-attachment?file=uploads/farmer-basic/original/' + item.attachment) : ''
          return Object.assign({}, item, imageData)
        })

        mappingData.map((item, index) => {
          const newRow = [
            { text: thisObject.$n(index + 1), style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'en') ? item.name : item.name_bn, style: 'td', alignment: 'center' },
            { text: item.email, style: 'td', alignment: 'center' },
            { text: thisObject.$n(item.nid, { useGrouping: false }), style: 'td', alignment: 'center' },
            { text: thisObject.$n('0') + thisObject.$n(item.mobile_no, { useGrouping: false }), style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'en') ? item.father_name : item.father_name_bn, style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'en') ? item.mother_name : item.mother_name_bn, style: 'td', alignment: 'center' }
          ]
          allRowsProgressTypes.push(newRow)
        })

        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['7%', '*', '*', '*', '*', '*', '*', '*'],
            body: allRowsProgressTypes
          }
        })

        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 7]
          },
          fertilizerSHeader: {
              fontSize: 10,
              margin: [40, 0, 0, 0]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: i18n.locale === 'bn' ? 14 : 12,
            margin: [0, 10, 0, 10]
          },
          header3: {
            fontSize: i18n.locale === 'bn' ? 13 : 11,
            margin: [0, 10, 0, 5]
          },
          surveyNoteStyle: {
            fontSize: i18n.locale === 'bn' ? 13 : 11,
            margin: [0, 50, 0, 5]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
